import { PkSpinner } from '@uefadigital/panenka-react';
import { memo } from 'react';

import * as styles from './style.module.scss';

export const Loader = ({ size = 100 }) => {
  return (
    <div className={styles.overlay}>
      <PkSpinner size={size}></PkSpinner>
    </div>
  );
};

export default memo(Loader);
